import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

import SmartFeedList from "../components/SmartFeedList";
import SmartFeedFilters from "../components/SmartFeedFilters";
import SmartFeedNavigation from "../components/SmartFeedNavigation";
import SmartFeedJobAlerts from "../components/SmartFeedJobAlerts";

import { useFiltersContext } from "../context/FiltersContext";

import fetchJsonp from "fetch-jsonp";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "../components/Analytics";
import { useTracking } from "react-tracking";

export default function HomepagePage(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: { config },
  } = props;

  const siteConfig = config;

  const apiKey = siteConfig.apiKey || props.smartfeedID;
  const gdprKey = siteConfig.gdprKey || props.apiKey || props.smartfeedID;
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [vacancyLoading, setVacancyLoading] = useState(true);

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    { page: "Homepage", location: props.location, apiKey },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, props.location, sessionIDValue, siteConfig]);

  useEffect(() => {
    if (apiKey) {
      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            "/CareerPage/" +
            "GetGroupDetails" +
            "?id=" +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function(response) {
            return response.json();
          })
          .then(function(jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                "/CareerPage/" +
                "GetApplicantGetwayURL" +
                "?companyId" +
                "=" +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function(response) {
                return response.json();
              })
              .then(function(appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function(ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, "error");
              });
          })
          .catch(function(ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, "error");
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError("No API key was provided.");
    }
  }, [apiKey, gdprKey, siteConfig.sfAPIEndpoint]);

  const companyName =
    siteConfig?.companyName || props?.smartfeedCompanyData?.CompanyName || "";

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: "postingtype",
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
    >
      {!vacanciesRouteLoading &&
      (siteConfig.siteConfig || smartfeedCompanyData) &&
      apiKey ? (
        <>
          <div
            className="banner"
            style={{
              backgroundRepeat: "no-repeat",
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/banner.jpg)`,
            }}
          >
            <h1 className="text-center text-white">HireRoad Careers</h1>
          </div>
          <div className="container py-3">
            <div className="row">
              <div className="col-12 mb-3">
                <SmartFeedNavigation
                  active={filters?.postingtype || "Careers"}
                  onNavChange={onNavChange}
                />
              </div>
            </div>
            {filters?.postingtype !== "Alerts" ? (
              <div className="row">
                <div className="col-12">
                  <SmartFeedFilters
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || apiKey}
                  />
                </div>
                <div className="col-12 p-0">
                  <SmartFeedList
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || apiKey}
                    smartfeedCompanyData={
                      siteConfig.smartfeedCompanyData || smartfeedCompanyData
                    }
                    trackEvent={trackEvent}
                    vacancyLoading={vacancyLoading}
                    setVacancyLoading={setVacancyLoading}
                  />
                </div>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                  <SmartFeedJobAlerts
                    siteConfig={siteConfig}
                    trackEvent={trackEvent}
                    apiKey={apiKey}
                    gdprKey={gdprKey}
                    location={siteConfig.showLocationJA}
                    region={siteConfig.showRegionJA}
                    category={siteConfig.showCategoryJA}
                    industry={siteConfig.showIndustryJA}
                    jobType={siteConfig.showJobTypeJA}
                    jobTime={siteConfig.showJobTimeJA}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
